










































































































import { Component, Vue } from "vue-property-decorator";
import { Dialog, Toast } from "vant";
import Mixin from "@/js/mixins";

@Component({ mixins: [Mixin] })
export default class HomeAgent extends Vue {
  public user_id = "";
  public user = {}; // 用户信息

  // 显示推荐码
  showParentName() {
    let user: any = this.user;
    Dialog.confirm({
      title: "推荐码",
      message: "您的推荐码为： " + user.user_name,
      confirmButtonText: "复制推荐码"
    }).then(() => {
      let copy: any = this.$refs.copy;
      copy.click();
    });
  }
  copy() {
    Toast("复制成功");
  }
  goShop(){
    alert(123)
  }
  // 获取用户信息
  getUserInfo() {
    let _this = this;
    this.$api.request({
      url: "user/users/user-info",
      data: { user_id: this.user_id },
      success(res) {
        _this.user = res.data;
        _this.$set(_this.user,'repertory',res.data.repertory)
        _this.$api.setTitle(res.data.user_type_name); // 设置标题
      }
    });
  }

  init() {
    this.user_id = String(this.$route.query.id || "");
    this.user = {};
    this.getUserInfo();
  }
}
